<template>
    <div>
      <v-form
         v-on:submit.prevent="DialogAceptar = true"
          ref="form"
          lazy-validation
      >
        <v-card>
        <v-card-title>
          <span class="headline">{{Accion}}</span>
          <v-spacer></v-spacer>
          <span v-if="Item.id != 0" class="headline">ID: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
             <v-row>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.nombre"
                  label="Nombre *"
                  hint="Nombre de la cuenta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.ruta"
                  label="Abreviatura *"
                  hint="Abreviatura de la cuenta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.link_soporte"
                  label="Link Soporte *"
                  hint="Link donde se dará soporte a la cuenta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.img_principal"
                  label="Imagen Principal *"
                  hint="Imagen utilizada para cotizador y ventas"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

             <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.img_venta"
                  label="Imagen Venta *"
                  hint="Imagen utilizada para entrega de producto"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.orden"
                  label="Orden *"
                  hint="Orden de la cuenta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  disabled
                  v-model="Item.renovar"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="Esta cuenta puede renovar?"
                  hint="Indique si la cuenta se puede renovar"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.activo"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="Cuenta está activa?"
                  hint="Indique si la cuenta se encuentra activo en el sistema"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

            </v-row>
          </v-container>

          <small>* indica campo requerido</small>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>
          <v-btn color="primary" type="submit">Guardar</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>

     <v-dialog v-model="DialogAceptar" persistent max-width="380">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{Accion}}
        </v-card-title>

        <v-card-text class="mt-4">
          <h2>¿Estas seguro de guardar?</h2>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="DialogAceptar = false">Cancelar</v-btn>
          <v-btn color="primary" @click="Guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'

export default ({

  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      }
  },

  created (){
    this.ConsultarProductosStreaming()
  },

  data: () => ({
    var: null,
    DialogAceptar: false,

    ItemEnviar: {
            id: null,
            nombre: null,
            ruta: null,
            link_soporte: null,
            img_principal: null,
            img_venta: null,
            orden: null,
            renovar: null,
            activo: null,
          },
  }),

  methods: {
     ...mapActions(['NuevoProductosStreaming', 'ConsultarProductosStreaming']),

    Guardar (){

      this.ItemEnviar.id = this.Item.id
      this.ItemEnviar.nombre = this.Item.nombre
      this.ItemEnviar.ruta = this.Item.ruta
      this.ItemEnviar.link_soporte = this.Item.link_soporte
      this.ItemEnviar.img_principal = this.Item.img_principal
      this.ItemEnviar.img_venta = this.Item.img_venta
      this.ItemEnviar.orden = this.Item.orden
      this.ItemEnviar.renovar = this.Item.renovar
      this.ItemEnviar.activo = this.Item.activo

      this.NuevoProductosStreaming(this.ItemEnviar)
      setTimeout( () => {this.ConsultarProductosStreaming()}, 500)
      this.DialogAceptar = false
    },

    Ocultar(){
        this.$emit('NuevoActivo', false)
    }
  },

  computed:{
    ...mapState(['Alerta']),
    ...mapGetters(['SINO'])
  }


})
</script>